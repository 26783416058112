import React, { useState } from 'react';
import BluetoothControl from '../BluetoothControl/BluetoothControl';
import BluetoothControlTB2v2 from '../BluetoothControl/BluetoothControlTB2v2';
import BluetoothControlCalibration from '../BluetoothControl/BluetoothControlCalibration';
import BluetoothControlSoccer from '../BluetoothControl/BluetoothControlSoccer';
import BluetoothControlRTS from '../BluetoothControl/BluetoothControlRTS';
import Wizards3ClientComponent from './Wizards3ClientComponent';
import Wizards3ClientComponentTB2v2 from './Wizards3ClientComponentTB2v2';
import CoinBoxCalibratorComponent from '../CoinBoxCalibratorComponent';
import CoinBoxSoccerComponent from '../CoinBoxSoccerComponent';
import CoinBoxRTSComponent from '../CoinBoxRTSComponent.js';
// import ReplayViewer from './ReplayViewer';
import { useAuth } from '../../contexts/AuthContext';

const Wizard3Main = () => {
  const { currentUser } = useAuth();
  const [CoinBox, setCoinbox] = useState(null);
  const [CoinBoxCalibrator, setCoinboxCalibrator] = useState(null);
  const [CoinBoxSoccer, setCoinBoxSoccer] = useState(null);
  const [CoinBoxRTS, setCoinBoxRTS] = useState(null);
  const [CoinBoxTB2v2, setCoinboxTB2v2] = useState(null);
  const [noCoins, setNoCoins] = useState(false);

  const handleNoCoinsChange = (e) => {
    setNoCoins(e.target.checked);
  };

  return (
    <div className="min-h-screen bg-gray-50 p-4 w-full">
      {!CoinBox && !CoinBoxCalibrator && !CoinBoxSoccer && !CoinBoxRTS && !CoinBoxTB2v2 &&
        <div>
          <h1 className="text-xl font-bold mb-4">{currentUser.email}</h1>
          <div className="mb-4">
            <label>
              <input
                type="checkbox"
                checked={noCoins}
                onChange={handleNoCoinsChange}
              />
              Enable No Coins mode
            </label>
          </div>
        </div>
      }
      {!CoinBoxCalibrator && !CoinBoxSoccer && !CoinBoxRTS && !CoinBoxTB2v2 && <BluetoothControl setCoinbox={setCoinbox} />}
      {!CoinBoxCalibrator && !CoinBoxSoccer && !CoinBoxRTS && !CoinBoxTB2v2 && CoinBox && <Wizards3ClientComponent coinbox={CoinBox} noCoins={noCoins} />}

      {!CoinBox && !CoinBoxCalibrator && !CoinBoxSoccer && !CoinBoxRTS && <BluetoothControlTB2v2 setCoinbox={setCoinboxTB2v2} />}
      {!CoinBox && !CoinBoxCalibrator && !CoinBoxSoccer && !CoinBoxRTS && CoinBoxTB2v2 && <Wizards3ClientComponentTB2v2 coinbox={CoinBoxTB2v2} noCoins={noCoins} />}

      {!CoinBox && !CoinBoxCalibrator && !CoinBoxSoccer && !CoinBoxTB2v2 && <BluetoothControlRTS setCoinbox={setCoinBoxRTS} />}
      {!CoinBox && !CoinBoxCalibrator && !CoinBoxSoccer && !CoinBoxTB2v2 && CoinBoxRTS && <CoinBoxRTSComponent coinbox={CoinBoxRTS} />}

      {!CoinBox && !CoinBoxCalibrator && !CoinBoxRTS && !CoinBoxTB2v2 && <BluetoothControlSoccer setCoinbox={setCoinBoxSoccer} />}
      {!CoinBox && !CoinBoxCalibrator && !CoinBoxRTS && !CoinBoxTB2v2 && CoinBoxSoccer && <CoinBoxSoccerComponent coinbox={CoinBoxSoccer} />}

      {!CoinBox && !CoinBoxSoccer && !CoinBoxRTS && !CoinBoxTB2v2 && <BluetoothControlCalibration setCoinbox={setCoinboxCalibrator} />}
      {!CoinBox && !CoinBoxSoccer && !CoinBoxRTS && !CoinBoxTB2v2 && CoinBoxCalibrator && <CoinBoxCalibratorComponent coinbox={CoinBoxCalibrator} />}

      <div>v1.9</div>
    </div>
  );
};
export default Wizard3Main;